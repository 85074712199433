import store from '@/store'

import { ref } from '@vue/composition-api'

export default function templateList() {
    const headers = [
        { text: '发送时间', value: 'create_at' },
        { text: '分类', value: 'type' },
        { text: '手机号', value: 'mobile' },
        { text: '关键内容', value: 'content' },
        { text: '全部内容', value: 'full_content' },
        { text: '操作', value: 'actions', align: 'center', sortable: false, width: 215 }
    ]
    const param = ref({
        key: '',
        query: '',
        options: { sortBy: ['id'], sortDesc: [false], page: 1, itemsPerPage: 10, state: "2" }
    })
    const table = ref({
        list: [],
        total: 0,
        snack: {
            show: false,
            msg: ''
        },
        loading: false,
        dialogComplete: {
            show: false,
        },
        selected: []
    })
    const fetchSendRecordList = () => {
        table.value.loading = true
        store.dispatch('content-sms-send-fail-list/fetchSendRecordList', param.value).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value.snack = {
                    show: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            const { list, total } = res.data.data
            table.value.list = list || []
            table.value.total = total
        }).catch(error => {
            console.log(error)
        }).finally(() => {
            table.value.dialogAddTemplate = {
                show: false,
                data: { id: '' }
            }
            table.value.loading = false
        }
        )
    }
    const completeState = () => {
        const ids = []
        table.value.selected.forEach(item => {
            ids.push(item.id)
        });
        table.value.loading = true
        store.dispatch('content-sms-send-fail-list/completeState', ids).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value.snack = {
                    show: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            table.value.dialogComplete.show = false
            fetchSendRecordList()
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }
    return {
        headers,
        param,
        table,
        fetchSendRecordList,
        completeState,
    }
}
